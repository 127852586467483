





































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { IPontoAcessoHistoricoAlteracoes } from '@/interfaces/ponto_acesso';

@Component
export default class Modal extends Vue {
  @Prop(Object) public pontoAcessoHistoricoAlteracoes: IPontoAcessoHistoricoAlteracoes[] | undefined;
  @Prop(Object) public pontoAcesso: object | undefined;
  public exibir: boolean = true;

  public headers = [
    {
      text: 'Data de alteração',
      value: 'data_alteracao',
      align: 'center',
    },
    {
      text: 'Usuário responsável pela alteração',
      value: 'usuario_alteracao',
      align: 'center',
    },
    {
      text: 'Campo alterado',
      value: 'campo_alterado',
      align: 'center',
    },
    {
      text: 'Valor antigo',
      value: 'valor_antigo',
      align: 'center',
    },
    {
      text: 'Valor novo',
      value: 'valor_novo',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  @Watch('exibir')
  public onExibirChanged(val: boolean, oldVal: boolean) {
    if (!val) {
      this.fecharModal();
    }
  }

  @Emit('modalClose')
  public fecharModal() {
    return;
  }

  @Emit('modalConfirm')
  public confirmar() {
    return;
  }
}
