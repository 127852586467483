import { readPontosAcesso } from '@/store/ponto_acesso/getters';
import { readLocalidades } from '@/store/localidade/getters';
import { readResponsaveis } from '@/store/responsavel/getters';
import { dispatchGetPontosAcesso } from '@/store/ponto_acesso/actions';
import { dispatchGetLocalidades } from '@/store/localidade/actions';
import { dispatchGetResponsaveis } from '@/store/responsavel/actions';

const cacheDominiosEstaticos = async (store) => {
    const dominiosEstaticos = [
        {
            getter: readPontosAcesso,
            dispatcher: dispatchGetPontosAcesso,
        },
        {
            getter: readLocalidades,
            dispatcher: dispatchGetLocalidades,
        },
        {
            getter: readResponsaveis,
            dispatcher: dispatchGetResponsaveis,
        },
    ];

    dominiosEstaticos.forEach((dominioEstatico) => {
        if (dominioEstatico.getter(store).length < 1) {
            dominioEstatico.dispatcher(store, 0);
        }
    });
};

export default cacheDominiosEstaticos;
