

























































































































import { Component, Vue } from 'vue-property-decorator';
import { readPontosAcesso } from '@/store/ponto_acesso/getters';
import { readLocalidades } from '@/store/localidade/getters';
import { readResponsaveis } from '@/store/responsavel/getters';
import { dispatchGetPontosAcesso, dispatchGetPontosAcessoFiltrados,
         dispatchGetHistoricoPontoAcesso, dispatchDeletePontoAcesso } from '@/store/ponto_acesso/actions';
import { IPontoAcesso, IPontoAcessoSelectFilter, IPontoAcessoHistoricoAlteracoes } from '@/interfaces/ponto_acesso';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';
import cacheDominiosEstaticos from '@/store/ponto_acesso/cache';
import ModalDetalhesLocalidade from '@/components/ModalDetalhesLocalidade.vue';
import ModalHistoricoPontoAcesso from '@/components/ModalHistoricoPontoAcesso.vue';
import { dispatchGetLocalidades } from '@/store/localidade/actions';

@Component({
  components: {
    ModalConfirmacao,
    ModalDetalhesLocalidade,
    ModalHistoricoPontoAcesso,
  },
})
export default class ListarPontosAcesso extends Vue {
  public loadingText = 'Carregando dados...';
  public isTableLoading = true;
  public modalExclusaoAtivo: boolean = false;
  public pontoAcessoExclusao;
  public show: boolean = false;
  public filtroMacAddress: string = '';
  public filtroLocalidade: string = '';
  public filtroResponsavel: string[] = new Array();
  public filtroStatus: boolean = false;
  public pontosAcessoFiltrados;
  public pontoAcessoSelecionado;
  public headerOrdenado;
  public localidadeDetalhes;
  public modalDetalhesLocalidadeAtivo: boolean = false;
  public pontoAcessoHistoricoAlteracoes: IPontoAcessoHistoricoAlteracoes[] | undefined;
  public modalHistoricoPontoAcessoAtivo: boolean = false;

  public headers = [
    {
      text: 'MAC Address',
      sortable: false,
      value: 'mac_address',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'macAddress',
    },
    {
      text: 'Código da localidade',
      sortable: false,
      value: 'localidade.codigo_localidade',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'codigoLocalidade',
    },
    {
      text: 'Responsável',
      sortable: false,
      value: 'responsavel.sigla',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'responsavel',
    },
    {
      text: 'Latitude',
      sortable: false,
      value: 'latitude',
      align: 'left',
      ordenacao: '',
      chaveFiltro: 'latitude',
    },
    {
      text: 'Longitude',
      sortable: false,
      value: 'longitude',
      align: 'left',
      ordenacao: '',
      chaveFiltro: 'longitude',
    },
    {
      text: 'Data de inclusão',
      sortable: false,
      value: 'created_at',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'dt_criacao',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'status',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'status',
    },
  ];

  public opcoesStatus = [
    {
      text: 'Somente ativos',
      value: 'true',
    },
    {
      text: 'Somente inativos',
      value: 'false',
    },
    {
      text: 'Todos',
      value: '',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  public ordenarListaPontosAcesso(header) {
    if (this.headerOrdenado && this.headerOrdenado !== header) {
      this.headerOrdenado.ordenacao = '';
    }

    if (header.ordenacao === 'arrow_upward') {
      header.ordenacao = 'arrow_downward';
    } else {
      header.ordenacao = 'arrow_upward';
    }
    this.headerOrdenado = header;
    this.aplicarFiltros(true, header.ordenacao, header.chaveFiltro);
  }

  public async abrirModalHistoricoPontoAcesso(pontoAcesso: IPontoAcesso) {
    this.pontoAcessoSelecionado = pontoAcesso;
    this.pontoAcessoHistoricoAlteracoes = await dispatchGetHistoricoPontoAcesso(this.$store, pontoAcesso.id);
    this.modalHistoricoPontoAcessoAtivo = true;
  }

  public async aplicarFiltros(aplicarOrdenacao, sentido, campoOrdenar) {
    if (this.headerOrdenado && campoOrdenar === '') {
      this.headerOrdenado.ordenacao = '';
    }

    const filtros: IPontoAcessoSelectFilter = {
        mac_address: this.filtroMacAddress,
        localidade: this.filtroLocalidade,
        reponsavel: this.filtroResponsavel,
        status: this.filtroStatus.toString(),
        ordenacao: aplicarOrdenacao,
        sentido,
        campoOrdenar,
      };
    await dispatchGetPontosAcessoFiltrados(this.$store, filtros);
    this.show = false;
    this.isTableLoading = false;
    this.loadingText = 'Não foram encontrados registros.';
  }

  public async limparFiltros() {
    this.filtroMacAddress = '';
    this.filtroLocalidade = '';
    this.filtroResponsavel = new Array();
    this.filtroStatus = false;

    await dispatchGetPontosAcesso(this.$store, 0);
    this.show = false;
  }

  public async updatePagination(pagination) {
    const totalRegistros = this.pontosAcesso.length;
    const itensPorPagina = pagination.rowsPerPage;
    const pagina = pagination.page;

    let paginasCache;
    if (totalRegistros > 0) {
      paginasCache = totalRegistros / itensPorPagina;
      if (paginasCache === pagina) {
        this.isTableLoading = true;
        await dispatchGetPontosAcesso(this.$store, totalRegistros);
        this.isTableLoading = false;
      }
    }
  }

  get pontosAcesso() {
    return readPontosAcesso(this.$store);
  }

  get localidades() {
    return readLocalidades(this.$store);
  }

  get responsaveis() {
    return readResponsaveis(this.$store);
  }

  public abrirModalExclusao(pontoAcesso: IPontoAcesso) {
    this.pontoAcessoExclusao = pontoAcesso;
    this.modalExclusaoAtivo = true;
  }

    public abrirModalDetalhesLocalidade(pontoAcesso) {
      this.localidadeDetalhes = pontoAcesso.localidade;
      this.modalDetalhesLocalidadeAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeletePontoAcesso(this.$store, this.pontoAcessoExclusao);
    dispatchGetPontosAcesso(this.$store, 0);
    dispatchGetLocalidades(this.$store, 0);
    this.modalExclusaoAtivo = false;
  }

  public async mounted() {
    if (this.$router.currentRoute.params.filtroLocalidade) {
      this.filtroLocalidade = this.$router.currentRoute.params.filtroLocalidade;
      await this.aplicarFiltros(false, '', '');
    } else {
      await cacheDominiosEstaticos(this.$store);
      if (!this.pontosAcesso.length) {
        this.isTableLoading = false;
        this.loadingText = 'Não foram encontrados registros.';
      }
    }
  }
}
